import React from 'react';
import { Row, Col, Form, Select, Checkbox, Switch } from "antd"
import PlaylistAddCheckOutlinedIcon from '@material-ui/icons/PlaylistAddCheckOutlined';
import SectionTitle from "./SectionTitle"

const AddOnServicesCommercial = ({onCheckboxChange}) => {
  return (
    <Row gutter={24} className="home-calc__section fade-in">
      <SectionTitle title="What services are you interested in">
        <PlaylistAddCheckOutlinedIcon/>
      </SectionTitle>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="standard">
          <div className="calc-switch">
            <Switch id="1" onChange={(checked) => onCheckboxChange('standard', checked)} />
            <label htmlFor="1">Standard Cleaning</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="sanitation">
          <div className="calc-switch">
            <Switch id="2" onChange={(checked) => onCheckboxChange('sanitation', checked)} />
            <label htmlFor="2">Sanitation & Disinfection (COVID-19)</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="recurring">
          <div className="calc-switch">
            <Switch id="3" onChange={(checked) => onCheckboxChange('recurring', checked)} />
            <label htmlFor="3">Recurring Cleanings</label>
          </div>
        </Form.Item>
      </Col>

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="moveInOut">
          <div className="calc-switch">
            <Switch id="4" onChange={(checked) => onCheckboxChange('moveInOut', checked)} />
            <label htmlFor="4">Move In/Out Cleaning</label>
          </div>
        </Form.Item>
      </Col>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item name="postCostruction">
          <div className="calc-switch">
            <Switch id="5" onChange={(checked) => onCheckboxChange('postCostruction', checked)} />
            <label htmlFor="5">Post-Construction Cleaning</label>
          </div>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default AddOnServicesCommercial;