import React from 'react';
import { Col, Typography } from 'antd';
import TextsmsOutlinedIcon from '@material-ui/icons/TextsmsOutlined';
import DraftsOutlinedIcon from '@material-ui/icons/DraftsOutlined';
import PhoneAndroidOutlinedIcon from '@material-ui/icons/PhoneAndroidOutlined';
import Link from 'gatsby-link'

const { Title } = Typography;

const ContactSidebar = () => {
  return (
    <Col xs={0} xl={{ span: 6, offset: 1}} className="calc-sidebar fade-in">
      <Title level={3}>Need Immediate Help?</Title>

      {/*<div className="calc-sidebar__contact">*/}
      {/*  <TextsmsOutlinedIcon/>*/}
      {/*  <div>*/}
      {/*    <div>Text:</div>*/}
      {/*    <Title level={5}><a href="tel:+13038483884">(303) 848-3884</a></Title>*/}
      {/*  </div>*/}
      {/*</div>*/}

      {/*<div className="calc-sidebar__contact">*/}
      {/*  <DraftsOutlinedIcon/>*/}
      {/*  <div>*/}
      {/*    <div>Email:</div>*/}
      {/*    <Link to="/contact">*/}
      {/*    <Title level={5}><span style={{color: '#088CCD', textDecoration: 'underline'}}>Contact Form</span></Title>*/}
      {/*    </Link>*/}
      {/*  </div>*/}
      {/*</div>*/}

      <div className="calc-sidebar__contact">
        <PhoneAndroidOutlinedIcon/>
        <div>
          <div>Phone:</div>
          <Title level={5}><a href="tel:+13038483884">(303) 848-3884</a></Title>
        </div>
      </div>
    </Col>
  );
};

export default ContactSidebar;