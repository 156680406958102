import React from 'react';
import { Row, Col, Form, Select, Checkbox, Input } from "antd"
import RecentActorsOutlinedIcon from '@material-ui/icons/RecentActorsOutlined';
import SectionTitle from "./SectionTitle"

const CompanyName = () => {
  return (
    <Row gutter={24} className="home-calc__section fade-in">
      <SectionTitle title="Company Name (or Building Name)">
        <RecentActorsOutlinedIcon/>
      </SectionTitle>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="company"
          rules={[
            {
              required: true,
              message: 'Please enter a company name'
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CompanyName;