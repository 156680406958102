import React from "react"
import { Typography } from "antd";
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import CalculatorCommercial from "../../components/CalculatorCommercial/CalculatorCommercial"

const { Title } = Typography;

const ContactPage = () => (
  <Layout className="calculator">
    <SEO title="Calculator" />

    <CalculatorCommercial/>

  </Layout>
)

export default ContactPage
