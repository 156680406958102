import React from 'react';
import { Row, Col, Form, Select } from "antd"
import LocationCityOutlinedIcon from '@material-ui/icons/LocationCityOutlined';
import SectionTitle from "./SectionTitle"

const { Option } = Select;

const PropertyType = () => {
  return (
    <Row gutter={24} className="home-calc__section fade-in">
      <SectionTitle title="Type of property">
        <LocationCityOutlinedIcon/>
      </SectionTitle>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="propertyType"
          rules={[
            {
              required: true,
              message: 'Please select the property type'
            },
          ]}
        >
          <Select
            // placeholder="Type of property"
            allowClear
          >
            <Option value="residential">Residential</Option>
            <Option value="commercial">Commercial</Option>
            <Option value="industrial">Industrial</Option>
            <Option value="publicSpace">Public Space</Option>
          </Select>
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PropertyType