import React from 'react';

const NetlifyCommercialForm = () => {
  return (
    <form
      name="Commercial Cleaning Form"
      data-netlify="true"
      hidden
    >
      <input type="text" name="firstName" />
      <input type="text" name="lastName" />
      <input type="text" name="email" />
      <input type="text" name="phone" />
      <input type="text" name="city" />
      <input type="text" name="zip" />
      <input type="text" name="area" />
      <input type="text" name="propertyType" />
      <input type="text" name="additionalInfo" />
      <input type="text" name="company" />
      <input type="text" name="standard" />
      <input type="text" name="sanitation" />
      <input type="text" name="recurring" />
      <input type="text" name="moveInOut" />
      <input type="text" name="postConstruction" />
      <input type="text" name="date" />
      <input type="text" name="time" />
    </form>
  )
}

export default NetlifyCommercialForm;