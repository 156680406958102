import "./CalculatorCommercial.less"
import React, { useState, useRef } from "react"
import { navigate } from "gatsby"
import {
  Row,
  Col,
  Form,
  Button,
  Typography,
} from "antd"
import PropertyType from "../CalculatorComponents/PropertyType"
import AddOnServicesCommercial from "../CalculatorComponents/AddOnServicesCommercial"
import PropertyArea from "../CalculatorComponents/PropertyArea"
import CompanyName from "../CalculatorComponents/CompanyName"
import ContactDetails from "../CalculatorComponents/ContactDetails"
import AdditionalInfo from "../CalculatorComponents/AdditionalInfo"
import ContactSidebar from "../CalculatorComponents/ContactSidebar"
import NetlifyCommercialForm from "./NetlifyCommercialForm"
import { encode, parseBoolValues } from "../../utils"
import Date from "../CalculatorComponents/Date"

const { Title } = Typography

const CalculatorCommercial = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const userDetailsRef = useRef(null);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');

  const onDateChange = (date, dateString) => {
    setDate(dateString);
  }

  const onTimeChange = (timeString) => {
    setTime(timeString)
  }

  const onFinish = values => {
    setLoading(true);
    const parsedValues = parseBoolValues(values)
    values.date = date
    values.time = time

    // Netlify
    fetch(`/`, {
      method: `POST`,
      body: encode({
        "form-name": `Commercial Cleaning Form`,
        ...parsedValues,
      }),
    })
      .then(
        () => {
          setLoading(false)
          // setSuccessVisible(true)
          navigate('/thank-you')
        },
        err => {
          console.log(err)
          setLoading(false)
        }
      )
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }

  const onReset = () => {
    form.resetFields()
  }

  const onCheckboxChange = (name, checked) => {
    let fieldValue = {}
    fieldValue[name] = checked
    form.setFieldsValue(fieldValue)
  }

  return (
    <Row justify="center" className="commercial-cal calc-wrapper">
      <Col xs={22} md={{ span: 16 }} lg={{ span: 16 }} xl={{ span: 12 }}>
        <Row gutter={24} justify="center" className="commercial-calc-header">
          <Col xs={22}>
            <Title>Commercial Cleaning Quote</Title>
            <Title level={4}>
              Provide some information regarding the property and we will follow
              up with a quote within 24 business hours
            </Title>
          </Col>
        </Row>
        <NetlifyCommercialForm />
        <Form
          form={form}
          name="control-hooks"
          onFinish={onFinish}
          layout="vertical"
          className="home-calc"
        >
          <PropertyType />
          <AddOnServicesCommercial onCheckboxChange={onCheckboxChange} />
          <Date onDateChange={onDateChange} onTimeChange={onTimeChange} />
          <PropertyArea />
          <CompanyName />
          <>
            <ContactDetails refProp={userDetailsRef} />
            <AdditionalInfo />

            <Row gutter={24} justify="center">
              <Col xs={24} style={{ textAlign: "center" }}>
                <Form.Item>
                  <Button
                    type="primary"
                    className="primary-cta"
                    htmlType="submit"
                    style={{ width: "100%" }}
                    loading={loading}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </>
        </Form>
      </Col>

      <Col xs={0} xl={{ span: 6, offset: 1 }} className="empty-placeholder" />
      <ContactSidebar />
    </Row>
  )
}

export default CalculatorCommercial
