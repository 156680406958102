import React from 'react';
import { Row, Col, Form, Select, Checkbox, Input } from "antd"
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import SectionTitle from "./SectionTitle"

const PropertyArea = () => {
  return (
    <Row gutter={24} className="home-calc__section fade-in">
      <SectionTitle title="Approximate Square Footage of Property">
        <DashboardOutlinedIcon/>
      </SectionTitle>
      <Col xs={{ span: 20, offset: 2 }} md={{ span: 24, offset: 0 }}>
        <Form.Item
          name="area"
          rules={[
            {
              required: true,
              message: 'Please enter the area of the property'
            },
          ]}
        >
          <Input type="text" />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default PropertyArea;